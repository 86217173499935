.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
@keyframes tipMove {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.alert-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9900;
  background: rgba(0, 0, 0, 0.5);
}
.confirm-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.58rem;
  margin-left: -2.31rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  width: 4.62rem;
  padding: 0.36rem 0.28rem 0.3rem;
  box-sizing: border-box;
  background: #fff;
  animation: tipMove 0.4s;
  border-radius: 4px;
}
.confirm-container .confirm-text {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.5rem;
  text-align: center;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-all;
}
.confirm-container .btn-group {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  margin-top: 0.1rem;
}
.confirm-container .btn-group .btn {
  width: 1.9rem;
  height: 0.72rem;
  border-radius: 18px;
  font-size: 0.28rem;
  line-height: 0.4rem;
  font-weight: 500;
  text-align: center;
}
.confirm-container .btn-group .cancel-btn {
  border: 1px solid #333;
  color: #333;
}
.confirm-container .btn-group .confirm-btn {
  background: var(--main-color);
  color: #fff;
}
.confirm-container .btn-group .delete-btn {
  background: #FF5252;
  color: #fff;
}
