.border-none[data-v-ffef46ba]:before {
  display: none;
}
.border-none[data-v-ffef46ba]:after {
  display: none;
}
.flex-fix[data-v-ffef46ba] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-ffef46ba]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-ffef46ba] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-ffef46ba] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-ffef46ba] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-ffef46ba] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-ffef46ba] {
  *zoom: 1;
}
.clear-fix[data-v-ffef46ba]:before,
.clear-fix[data-v-ffef46ba]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
@-webkit-keyframes tipMove-ffef46ba {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
35% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@keyframes tipMove-ffef46ba {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
35% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
.alert-container[data-v-ffef46ba] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9900;
  background: rgba(0, 0, 0, 0.5);
}
.confirm-container[data-v-ffef46ba] {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.58rem;
  margin-left: -2.31rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  width: 4.62rem;
  padding: 0.36rem 0.28rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  -webkit-animation: tipMove-ffef46ba 0.4s;
          animation: tipMove-ffef46ba 0.4s;
  border-radius: 4px;
}
.confirm-container .confirm-text[data-v-ffef46ba] {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.5rem;
  text-align: center;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-all;
}
.confirm-container .btn-group[data-v-ffef46ba] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  margin-top: 0.1rem;
}
.confirm-container .btn-group .btn[data-v-ffef46ba] {
  width: 1.9rem;
  height: 0.72rem;
  border-radius: 18px;
  font-size: 0.28rem;
  line-height: 0.4rem;
  font-weight: 500;
  text-align: center;
}
.confirm-container .btn-group .cancel-btn[data-v-ffef46ba] {
  border: 1px solid #333;
  color: #333;
}
.confirm-container .btn-group .confirm-btn[data-v-ffef46ba] {
  background: var(--main-color);
  color: #fff;
}
.confirm-container .btn-group .delete-btn[data-v-ffef46ba] {
  background: #FF5252;
  color: #fff;
}
